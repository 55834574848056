import { keyframes } from '@emotion/react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import Button from '../../components/button'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Text from '../../components/text'
import { flatten } from '../../utils/data'

const NotFoundPage = ({ data: { pageNotFound } }) => {
  const page = useMemo(
    () =>
      flatten(pageNotFound || {}, [
        'pageHeader',
        'pageSubtitle',
        'pageMainContent',
        'returnToHomepageCta',
      ]),
    [pageNotFound]
  )

  const pageContent = (
    <Heading
      headingType="h1"
      content={page.pageMainContent}
      style={tw`inline-block px-8 font-bold whitespace-nowrap md:px-32`}
    />
  )

  const scroll = keyframes`
    0% {
      transform: translateX(calc(-25%));
    }
    100% {
      transform: translateX(calc(-50%));
    }
    &:hover {
      animation-play-state: paused;
      }
    }
  `

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex}>
      <section css={tw`flex items-center h-screen overflow-hidden`}>
        <div
          css={tw`flex flex-col items-start max-w-full mx-auto first-of-type:pt-0 h-1/2 w-8/10 md:h-3/4`}
        >
          <div css={tw`w-full`}>
            <Heading content={page.pageHeader} style={tw`font-bold`} headingType="h6" />
          </div>
          <div
            css={css`
              width: fit-content;
              animation: ${scroll} 12s linear infinite;
              ${tw`relative flex -translate-x-40 my-15/10`}
            `}
          >
            {pageContent}
            {pageContent}
            {pageContent}
            {pageContent}
          </div>
          <div css={tw`w-full lg:w-1/3`}>
            <div css={tw`mt-8`}>
              <Text content={page.pageSubtitle} />
              <div css={tw`flex mt-16`}>
                <Button
                  label={get(page, 'returnToHomepageCta.callToAction.label')}
                  link="/"
                  type="primary"
                  size="xs"
                  theme="navy-cyan"
                  hideCaret
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    pageNotFound: PropTypes.object.isRequired,
  }),
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    pageNotFound: datoCmsPageNotFound {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      pageHeader
      pageSubtitle
      pageMainContent
      returnToHomepageCta {
        callToAction {
          label
        }
      }
    }
  }
`
